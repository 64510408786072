/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Backdrop, Box, Button, CircularProgress, Collapse, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Theme, Typography, useTheme } from '@mui/material';
import Axios, { AxiosRequestConfig } from "axios";
//import { ReactComponent as ProductIcon } from "../../../common/icons/productIcon.svg"

import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { add, format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb'
import { ChangeEvent, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { CheckCircle, Description, Edit, ExpandLess, ExpandMore, Launch, PhotoCamera, PictureAsPdf, Refresh } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import OrderService from '../../../services/OrderService';
import { IOrderModel } from '../../../models/IOrderModel';
import { toast } from 'react-toastify';
import { StylesTableCell, StylesTableRow } from '../../../common/CustumEmotionStyles';


interface IOrders {
    orderNr: number,
    customerNa: string,
    orderDate: Date,
    deliveryDate: Date,
}

const Orders = withErrorBoundary((): JSX.Element => {

    const theme = useTheme();

    const { hideHiddenFromFrontPage } = useParams();

    //###############################################################################

    const [orders, setOrders] = useState<IOrderModel[]>([]);
    const [ordersToggle, setOrdersToggle] = useState(false);
    const [errorOrders, setErrorOrders] = useState<string>("");
    const [loader, setLoader] = useState(false);

    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    function assignActiveListItem(item: number) {
        if (item === activeListItem)
            setActiveListItem(null);
        else
            setActiveListItem(item);
    }

    const [fileMessage, setFileMessage] = useState<string>("");
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, orderId: number) => {

        const file: File | null = e.target.files ? e.target.files[0] : null;

        if (file && file['type'].split('/')[0] === 'image') {
            servicePostOrderImage(orderId, file);
        }
        else {
            toast("Ingen eller ugyldig fil valgt. Bruk bildefil");
        }
        e.target.value = "";
    }

    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    const serviceGetOrders = async (isMounted: boolean) => {
        const orderService = new OrderService();
        setLoader(true);
        const result = await orderService.getOrderList();
        setLoader(false);
        if (result.hasErrors) {
            if (isMounted) setOrders([]);
            if (isMounted) toast("Kan ikke hente ADR liste");
            if (isMounted) setErrorOrders("Kan ikke hente ADR liste. Kontakt systemansvarlig");
        } else {
            if (isMounted) setOrders(result.value);
        }
    }

    const servicePostOrderImage = async (orderId: number, file: File) => {
        const orderService = new OrderService();
        setLoader(true)
        const result = await orderService.postOrderImage(orderId, file);
        setLoader(false);
        if (result.hasErrors) {
            toast("Kan ikke laste opp fil. Svar fra server: " + result.errors.join(", "));
            setFileMessage("Kan ikke laste opp fil. Svar fra server: " + result.errors.join(", "));
        }
        else {
            toast("Bilde lastet opp!");
            setFileMessage("Bilde lastet opp!")
        }
    }

    const serviceGetConsignmentNote = async (orderId: number) => {
        const orderService = new OrderService();
        setLoader(true)
        const result = await orderService.getOrderFile(orderId);
        setLoader(false);
        if (result.hasErrors) {
            toast("Klarer ikke å åpne fil. Svar fra server: " + result.errors.join(", "));
        } else {
            window.open(URL.createObjectURL(result.value), "_blank");
        }
    }
    const servicePostOrderHiddenFromFrontPage = async (orderId: number, state: boolean, newList: IOrderModel[]) => {
        const orderService = new OrderService();
        setLoader(true)
        const result = await orderService.postOrderHiddenFromFrontPage(orderId, state);
        setLoader(false);
        if (result.hasErrors) {
            toast("Klarer ikke å vise/skjule ordre. Svar fra server: " + result.errors.join(", "));
        } else {
            setOrders(newList);
        }
    }

    useEffect(() => {
        let isMounted = true; //Avoid memory leaks. Async function running after dismount.
        serviceGetOrders(isMounted)

        return () => { isMounted = false };

    }, [ordersToggle]);


    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <ErrorMessage message={message} />
    }

    function handleHideFromFrontPage(q: MouseEvent<HTMLButtonElement | MouseEvent>, orderIndex: number, orderId: number, state: boolean) {
        q.stopPropagation();
        let newList = orders.map((e, index) => {
            if (index === orderIndex) {
                return {
                    id: e.id,
                    customerName: e.customerName,
                    orderDate: e.orderDate,
                    deliveryAddress: e.deliveryAddress,
                    hasConsignmentNote: e.hasConsignmentNote,
                    lines: e.lines,
                    userOrderProperties: { hiddenFromFrontPage: state }
                }
            } else {
                return e
            }
        });
        servicePostOrderHiddenFromFrontPage(orderId, state, newList);
    }

    return <Grid container component="main" direction="column" wrap="nowrap"
        sx={{
            width: "100%",
            height:"100%"
            //height: {
            //    //xs: "calc(100vh - " + appBarHeight + "px )",
            //    lg: "calc(100vh)"
            //},
        }}>
        <Container maxWidth="md">
            <Paper sx={{ height: "100%", marginTop: theme.spacing(2) }}>
                <List>
                    <ListItem sx={{ display: "flex", justifyContent: "center" }} secondaryAction={<IconButton onClick={() => serviceGetOrders(true)}><Refresh fontSize="large"/></IconButton>}>
                        <Typography variant="button" fontSize="1.2rem" fontWeight="bold">
                            {hideHiddenFromFrontPage === "true" ? "Ordre" : "Alle ordre"}
                        </Typography>
                    </ListItem>
                    {orders.map((e, index) => {

                        let orderDate = format(new Date(e.orderDate), "dd.MM.yyyy", { locale: nbLocale });

                        if (hideHiddenFromFrontPage && hideHiddenFromFrontPage === "true" && e.userOrderProperties.hiddenFromFrontPage === true) {
                            return <></>;
                        } else {
                            return <Box sx=
                                {{
                                    marginLeft: theme.spacing(1), marginRight: theme.spacing(1), marginTop: theme.spacing(1),
                                    border: 1,
                                    borderRadius: theme.shape.borderRadius,
                                    bgcolor: index % 2 === 0 ? theme.palette.secondary.dark : theme.palette.secondary.main,
                                    borderColor: theme.palette.secondary.main,
                                }}>
                                <ListItemButton onClick={() => assignActiveListItem(index)}>
                                    <ListItemText
                                        primary={
                                            <Grid container spacing={2}>
                                                <Grid container wrap="nowrap">
                                                    <Grid><Typography fontWeight="bold">Ordre:</Typography></Grid>
                                                    <Grid><Typography>{" " + e.id + " "}</Typography></Grid>
                                                </Grid>
                                                <Grid container >
                                                    <Grid><Typography fontWeight="bold">Kunde:</Typography></Grid>
                                                    <Grid><Typography>{e.customerName}</Typography></Grid>
                                                </Grid>
                                                <Grid flexGrow={1}></Grid>
                                                <Grid>
                                                    <Button
                                                        color={e.userOrderProperties.hiddenFromFrontPage ? "success" : "error"}
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ height: "1.5rem" }}
                                                        onClick={(q: MouseEvent<HTMLButtonElement | MouseEvent>) => handleHideFromFrontPage(q, index, e.id, !e.userOrderProperties.hiddenFromFrontPage)}>
                                                        {e.userOrderProperties.hiddenFromFrontPage ? "Vis" : "Skjul"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                        secondary={
                                            <Grid container spacing={2}>
                                                <Grid container wrap="nowrap">
                                                    <Grid><Typography fontWeight="bold">Ordredato:</Typography></Grid>
                                                    <Grid><Typography>{" " + orderDate + " "}</Typography></Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid><Typography fontWeight="bold">Leveringsadresse:</Typography></Grid>
                                                    <Grid><Typography>{" " + e.deliveryAddress.postArea + " " + e.deliveryAddress.postalCode + ", " + e.deliveryAddress.street}</Typography></Grid>
                                                </Grid>
                                                <Grid container width="100%">
                                                    <Grid><Typography fontWeight="bold">Fil skannet: </Typography></Grid>
                                                    <Grid><Typography fontWeight="bold" color={e.hasConsignmentNote ? "green" : "red"}>{e.hasConsignmentNote ? "JA" : "NEI"}</Typography></Grid>
                                                </Grid>
                                            </Grid>
                                        } />
                                    <ListItemIcon>
                                        {index === activeListItem ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                </ListItemButton>
                                <Collapse in={index === activeListItem ? true : false} timeout="auto" unmountOnExit
                                    sx={{
                                        padding: theme.spacing(1),
                                        bgcolor: theme.palette.common.white,
                                        border: 1,
                                        borderColor: theme.palette.secondary.main
                                    }}>
                                    <Grid container spacing={2}>
                                        <Grid xs={6}>
                                            <Button
                                                fullWidth
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                endIcon={<PhotoCamera />}>
                                                Skann fraktbrev
                                                <input
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    hidden
                                                    onChange={((f: ChangeEvent<HTMLInputElement>) => handleFileChange(f, e.id))} />
                                            </Button>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Button
                                                onClick={() => serviceGetConsignmentNote(e.id)}
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                endIcon={<PictureAsPdf />}>
                                                Hent fraktbrev
                                            </Button>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Button component={Link} to={"/Acinor/Order/" + e.id}
                                                fullWidth
                                                variant="contained"
                                                color="warning"
                                                endIcon={<Description />}>
                                                Design fraktbrev
                                            </Button>
                                        </Grid>
                                        <Grid xs={12}>
                                            <TableContainer sx={{
                                                borderBottomLeftRadius: theme.shape.borderRadius,
                                                borderBottomRightRadius: theme.shape.borderRadius
                                            }}>
                                                <Table sx={{ width: "100%" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell css={StylesTableCell(theme)}>Produktnummer</TableCell>
                                                            <TableCell css={StylesTableCell(theme)} align="right">Antall</TableCell>
                                                            <TableCell css={StylesTableCell(theme)} align="right">Beskrivelse</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {e.lines.map(row => (
                                                            <TableRow css={StylesTableRow(theme)} key={row.lineNbr}>
                                                                <TableCell css={StylesTableCell(theme)} component="th" scope="row">{row.productNumber}</TableCell>
                                                                <TableCell css={StylesTableCell(theme)} align="right">{row.quantity + " " + row.unitOfMeasure}</TableCell>
                                                                <TableCell css={StylesTableCell(theme)} align="right">{row.lineDescription}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        }
                    })}
                </List>
            </Paper>
        </Container>
        <Backdrop sx={{ color: '#fff', }} open={loader}>
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress size="6rem" thickness={6} />
            </Box>
        </Backdrop>
    </Grid>
})

export default Orders;
