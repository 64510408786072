import { Result, DefaultResult } from "./Result";
import Axios, { AxiosRequestConfig } from "axios";
import { isNode, showErrors, getNodeProcess, RedirectToLogin } from "../utils";
import SessionManager from "./session";
import PageResult from "./PageResult";
import queryString from "query-string";
import { getTokenFromLocalStorage, removeTokenFromLocalStorage } from "../App";

export interface IRequestOptions {
    url: string;
    data?: any;
    token?: string;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

export interface ISendFormDataOptions {
    url: string;
    data: FormData;
    token?: string;
    method: "POST" | "PUT" | "PATCH";
}

/**
 * Represents base class of the isomorphic service.
 */
export abstract class ServiceBase {

    /**
     * Make request with JSON data.
     * @param opts
     */

    public async requestJsonDefault(opts: IRequestOptions): Promise<DefaultResult> {

        var axiosResult = null;
        var result = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        var axiosRequestConfig: AxiosRequestConfig;


        // Make SSR requests 'authorized' from the NodeServices to the web server.
        axiosRequestConfig = {
            headers: {
                Authorization: getTokenFromLocalStorage(),
            }
        }
        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
            }
            result = new Result(axiosResult.status, axiosResult.data?.value, ...axiosResult.data?.errors);
        } catch (error: any) {
            if (error && error.message)
                result = new Result(null, null, error.message);
            else
                result = new Result(null, null, "Error with API communication. Contact system developer.");
        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }
        if (result.code && result.code === 401) {
            removeTokenFromLocalStorage();
            RedirectToLogin();
        }
        return result;
    }
    public async requestJson<T>(opts: IRequestOptions): Promise<Result<T>> {

        var axiosResult = null;
        var result = null;
        var status = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        var axiosRequestConfig : AxiosRequestConfig;

        const token = getTokenFromLocalStorage();

        axiosRequestConfig = {
            headers: {
                Authorization: getTokenFromLocalStorage(),
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        }
        

        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
            }
            status = axiosResult.status;
            result = new Result(axiosResult.status, axiosResult.data?.value, ...axiosResult.data?.errors);
        } catch (error: any) {
            if (error && error.message)
                result = new Result(status, null, error.message);
            else
                result = new Result(status, null, "Error with API communication. Contact system developer.");
            
        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }
        if ((result.code && result.code === 401)) {
            removeTokenFromLocalStorage();
            RedirectToLogin();
        }
        return result;
    }

    /**
    * Make request with JSON data.
    * @param opts
    */
    public async requestJsonPaged<T>(opts: IRequestOptions): Promise<PageResult<T>> {

        var axiosResult = null;
        var result = null;
        var status = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        var axiosRequestConfig: AxiosRequestConfig;

        // Make SSR requests 'authorized' from the NodeServices to the web server.
        axiosRequestConfig = {
            headers: {
                Authorization: getTokenFromLocalStorage(),
            }
        }

        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
            }
            status = axiosResult.status;
            result = new PageResult(axiosResult.status, axiosResult.data?.value, axiosResult.data?.currentPage, axiosResult.data?.totalPages, axiosResult.data?.totalItems, ...axiosResult.data?.errors);
        } catch (error: any) {
            if (error && error.message)
                result = new PageResult(status, null, 1, 0, 0, error.message);
            else
                result = new PageResult(status, null, 1, 0, 0, "Error with API communication. Contact system developer.");
        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }
        if (result.code && result.code === 401) {
            removeTokenFromLocalStorage();
            RedirectToLogin();
        }
        return result;
    }
    public async requestFile<T>(opts: IRequestOptions): Promise<Result<T>> {

        var axiosResult = null;
        var result = null;
        var status = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        var axiosRequestConfig: AxiosRequestConfig;

        axiosRequestConfig = {
            responseType: "blob",
            headers: {
                Authorization: getTokenFromLocalStorage(),
            },
            validateStatus: (status) => {
                return status >= 200 && status < 500
            }
        }
        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
            }
            status = axiosResult.status;
            result = new Result(axiosResult.status, axiosResult.data);
        } catch (error: any) {
            if (error && error.message)
                result = new Result(status, null, error.message);
            else
                result = new Result(status, null, "Error with API communication. Contact system developer.");

        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }
        if (result.code && result.code === 401) {
            removeTokenFromLocalStorage();
            RedirectToLogin();
        }
        return result;
    }
    /**
     * Allows you to send files to the server.
     * @param opts
     */
    public async sendFormData<T>(opts: ISendFormDataOptions): Promise<Result<T>> {
        let axiosResult = null;
        let result = null;

        opts.url = opts.url; // Allow requests also for Node.

        var axiosOpts = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: getTokenFromLocalStorage(),
            }
        };

        try {
            switch (opts.method) {
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosOpts);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosOpts);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosOpts);
                    break;
            }
            result = new Result(axiosResult.status, axiosResult.data?.value, ...axiosResult.data?.errors);
        } catch (error: any) {
            if (error && error.message)
                result = new Result(null, null, error.message);
            else
                result = new Result(null, null, "Error with API communication. Contact system developer.");
        }

        if (result.hasErrors) {
            showErrors(...result.errors);
        }
        if (result.code && result.code === 401) {
            removeTokenFromLocalStorage();
            RedirectToLogin();
        }
        return result;
    }
}