/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Backdrop, Box, Button, CircularProgress, Collapse, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';

//import { ReactComponent as ProductIcon } from "../../../common/icons/productIcon.svg"

import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { add, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { CheckCircle, Delete, Edit } from '@mui/icons-material';
import { IADRModel } from '../../../models/IADRModel';
import ADRService from '../../../services/ADRService';
import { toast } from 'react-toastify';

interface IOrders {
    orderNr: number,
    customerNa: string,
    orderDate: Date,
    deliveryDate: Date,
}

const AdrDatabase = withErrorBoundary((): JSX.Element => {

    const theme = useTheme();
    //###############################################################################

    const [newAdr, setNewAdr] = useState<boolean>(false);
    const [adrCode, setAdrCode] = useState<string>("");
    const [adrDescription, setAdrDescription] = useState<string>("");
    const [newAdrDescription, setNewAdrDescription] = useState<string>("");

    const [loader, setLoader] = useState(false);

    const [adrList, setAdrList] = useState<IADRModel[]>([]);
    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    const [updateAdrList, setUpdateAdrList] = useState<boolean>(false);
    function assignActiveListItem(item: number) {
        setNewAdrDescription("");
        if (item === activeListItem)
            setActiveListItem(null);
        else
            setActiveListItem(item);
    }
    const [errorGetList, setErrorGetList] = useState("");
    const [errorDeleteLine, setErrorDeleteLine] = useState("");
    const [errorPostLine, setErrorPostLine] = useState("");

    function cancelAddUser() {
        setNewAdr(false);
        setAdrCode("");
        setAdrDescription("");
    }

    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    const serviceGetADRList = async (isMounted: boolean, adrService: ADRService) => {
        setLoader(true);
        const result = await adrService.getADRList();
        setLoader(false);
        if (result.hasErrors) {
            if (isMounted) setAdrList([]);
            if (isMounted) toast("Kan ikke hente ADR liste");
            if (isMounted) setErrorGetList("Kan ikke hente ADR liste. Kontakt systemansvarlig");
        } else {
            if (isMounted) setAdrList(result.value);
        }
    }
    const serviceDeleteADRLine = async (adrCode: number) => {
        const adrService = new ADRService();
        setLoader(true);
        const result = await adrService.deleteADRLine(adrCode);
        setLoader(false);
        if (result.hasErrors) {
            toast("Kan ikke slette ADR-kode: " + adrCode + ", svar fra server: " + result.errors[0]);
            setErrorDeleteLine("Kan ikke slette ADR-kode: " + adrCode + ", svar fra server: " + result.errors[0]);
        }
        setUpdateAdrList(!updateAdrList);
    }
    const servicePostADRLine = async (adrCode: number, adrDescription: string) => {
        const adrService = new ADRService();
        if (adrCode && !isNaN(adrCode) && adrDescription !== "") {
            setLoader(true);
            const result = await adrService.postADRLine(adrCode, adrDescription);
            setLoader(false);
            if (result.hasErrors) {
                toast("Kan ikke å legge til ADR-kode: " + adrCode + ", kontakt systemansvarlig");
                setErrorDeleteLine("Kan ikke å legge til ADR-kode: " + adrCode + ", kontakt systemansvarlig");
            }
            setUpdateAdrList(!updateAdrList);
            setAdrCode("");
            setAdrDescription("");
            setNewAdrDescription("");
        } else {
            toast("Feil i Adr-Kode eller Adr-Beskrivelse. Beskrivelse kan ikke stå tomt")
        }
    }

    useEffect(() => {
        let isMounted = true; //Avoid memory leaks. Async function running after dismount.
        const adrService = new ADRService();

        serviceGetADRList(isMounted, adrService);

        return () => { isMounted = false };

    }, [updateAdrList]);

    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <ErrorMessage message={message} />
    }
    return <Grid container component="main" direction="column" wrap="nowrap"
        sx={{
            width: "100%",
            height:"100%"
            //height: {
            //    //xs: "calc(100vh - " + appBarHeight + "px )",
            //    lg: "calc(100vh)"
            //},
        }}>
        <Container maxWidth="md">
            <Paper sx={{ height: "100%", marginTop: theme.spacing(2) }}>
                <Button
                    onClick={() => setNewAdr(true) }
                    sx={{ display: newAdr ? "none" : "block" }}
                    fullWidth
                    variant="contained">
                    Legg til ny ADR linje
                </Button>
                <Collapse in={newAdr} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>
                        <Grid xs={3}>
                            <TextField fullWidth size="small" variant="outlined" color="warning" value={adrCode}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                onChange={(e) => setAdrCode(e.target.value)}
                                id="adrId" label="ADR nummer" />
                        </Grid>
                        <Grid xs={7}>
                            <TextField fullWidth size="small" variant="outlined" color="warning" value={adrDescription}
                                onChange={(e) => setAdrDescription(e.target.value)}
                                id="adrId" label="ADR Navn" />
                        </Grid>
                        <Grid xs={2}>
                            <Button
                                onClick={() => servicePostADRLine(parseInt(adrCode), adrDescription)}
                                fullWidth
                                variant="contained"
                                color="secondary">Legg til
                            </Button>
                        </Grid>
                        <Grid xs={12}>
                            <Button onClick={() => cancelAddUser()} fullWidth variant="contained" color="warning">Lukk</Button>
                        </Grid>
                    </Grid>
                </Collapse>
                <List sx={{ marginTop: "1rem" }} subheader={
                    <ListSubheader>
                        <Typography variant="h6">Endre/Slett eksiterende ADR:</Typography>
                    </ListSubheader>
                }>
                    {adrList.map((e, index) => {

                        return <Box sx=
                            {{
                                marginLeft: theme.spacing(1), marginRight: theme.spacing(1), marginTop: theme.spacing(1),
                                border: 1,
                                borderRadius: theme.shape.borderRadius,
                                bgcolor: index % 2 === 0 ? theme.palette.secondary.dark : theme.palette.secondary.main,
                                borderColor: theme.palette.secondary.main,
                            }}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Grid container spacing={2}>
                                            <Grid container wrap="nowrap">
                                                <Grid><Typography fontWeight="bold">Id:</Typography></Grid>
                                                <Grid><Typography>{e.code}</Typography></Grid>
                                            </Grid>
                                            <Grid container >
                                                <Grid><Typography fontWeight="bold">Beskrivelse:</Typography></Grid>
                                                <Grid><Typography>{e.description}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    } />
                                <ListItemIcon>
                                    <IconButton onClick={() => assignActiveListItem(index)}>
                                        <Edit color="warning" />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton onClick={() => serviceDeleteADRLine(e.code)}>
                                        <Delete color="error" />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                            <Collapse in={index === activeListItem ? true : false} timeout="auto" unmountOnExit
                                sx={{
                                    padding: theme.spacing(3),
                                    bgcolor: theme.palette.common.white,
                                    border: 1,
                                    borderColor: theme.palette.secondary.main
                                }}>
                                <Grid container spacing={2} alignItems="flex-start">
                                    <Grid xs={5}>
                                        <TextField multiline maxRows={4} fullWidth size="small" variant="outlined" color="warning" value={newAdrDescription}
                                            onChange={(e) => setNewAdrDescription(e.target.value)}
                                            id="adrId" label="Ny ADR beskrivelse" />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Button onClick={() => servicePostADRLine(e.code, newAdrDescription)} fullWidth variant="contained" color="error" >
                                            Endre
                                        </Button>
                                    </Grid>
                                    <Grid xs={3}>
                                        <Button onClick={() => assignActiveListItem(index)} fullWidth variant="contained" color="warning" >
                                            Lukk
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Box>
                    })}
                </List>
            </Paper>
        </Container>
        <Backdrop sx={{ color: '#fff', }} open={loader}>
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress size="6rem" thickness={6} />
            </Box>
        </Backdrop>
    </Grid>
})

export default AdrDatabase;
