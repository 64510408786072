import { add, sub } from 'date-fns'
import { IADRModel } from '../models/IADRModel';
import { DefaultResult, Result } from '../rest_api_connection/Result';
import { ServiceBase } from '../rest_api_connection/ServiceBase';


export default class ADRService extends ServiceBase {

    public async getADRList(
    ): Promise<Result<IADRModel[]>> {

        let url = "/ADR";

        var result = await this.requestJson<IADRModel[]>({
            url: url,
            method: "GET",
        });

        return result;
    }

    public async postADRLine(code: number, description?: string): Promise<DefaultResult> {

        let url = "/ADR/" + code;
        if (description)
            url = url + "?description=" + encodeURIComponent(description)

        var result = await this.requestJsonDefault({
            url: url,
            method: "POST",
        });
        return result;
    }

    public async deleteADRLine(code: number): Promise<DefaultResult> {

        let url = "/ADR/" + code;

        var result = await this.requestJsonDefault({
            url: url,
            method: "DELETE",
        });
        return result;
    }
}