/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Backdrop, Box, Button, CircularProgress, Container, List, ListItem, ListItemText, Paper, Typography, useTheme } from '@mui/material';

//import { ReactComponent as ProductIcon } from "../../../common/icons/productIcon.svg"

import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { useEffect, useRef, useState } from 'react';
import { ExitToApp, Print, RestartAlt } from '@mui/icons-material';
import { toast } from 'react-toastify';
import OrderService from '../../../services/OrderService';
import { IConsignmentLinesModel, IConsignmentNoteModel, IConsignmentsModel } from '../../../models/IConsignmentNoteModel';
import { Link, useParams } from 'react-router-dom';
import ConsignmentTable from '../components/ConsignmentTable';

const OrderConsignments = withErrorBoundary((): JSX.Element => {

    const theme = useTheme();
    //###############################################################################

    const { orderId } = useParams();

    const [loader, setLoader] = useState(false);
    const [consignmentNoteData, setConsignmentNoteData] = useState<IConsignmentNoteModel | null>(null);
    const [consignments, setConsignment] = useState<IConsignmentsModel[]>([]);
    const [consignmentNoteDataError, setConsignmentNoteDateError] = useState("");
    const consignmentsRef = useRef<IConsignmentsModel[]>([]);

    const [revert, setRevert] = useState(false);

    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    const serviceGetOrderConsignmentNoteData = async (isMounted: boolean, orderService: OrderService, orderId: number) => {
        setLoader(true);
        const result = await orderService.getOrderConsignmentNoteData(orderId);
        setLoader(false);
        if (result.hasErrors) {
            if (isMounted) setConsignmentNoteData(null);
            if (isMounted) toast("Kan ikke hente ordrelinjer");
            if (isMounted) setConsignmentNoteDateError("Kan ikke hente ordrelinje liste. Kontakt systemansvarlig. Svar fra server: " + result.errors.join(", "));
        } else {
            if (isMounted) setConsignmentNoteData(result.value);
            if (isMounted) setConsignment(result.value.consignments);
            if (isMounted) consignmentsRef.current = result.value.consignments;
        }
    }

    useEffect(() => {
        let isMounted = true; //Avoid memory leaks. Async function running after dismount.
        const orderService = new OrderService();

        if (orderId) {
            serviceGetOrderConsignmentNoteData(isMounted, orderService, parseInt(orderId));
        }

        return () => { isMounted = false };

    }, [orderId, revert]);


    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <ErrorMessage message={message} />
    }

    //let orderDate = "";
    //if (consignmentNoteData) {
    //    orderDate = format(new Date(consignmentNoteData.date), "dd.MM.yyyy", { locale: nbLocale });
    //}
    function addConsignment() {
        let newConsignment: IConsignmentsModel = {
            quantity: 0,
            lines: [],
        }
        let updatedConsignmentList = [...consignmentsRef.current, newConsignment];
        consignmentsRef.current = updatedConsignmentList;
        setConsignment(updatedConsignmentList);
    }
    function updateConsignmentLine(consignmentIndex: number, newLines: IConsignmentLinesModel[]) {
        let updatedConsignments = consignmentsRef.current.map((e, index) => {
            if (index === consignmentIndex) {
                return { quantity: e.quantity, lines: newLines };
            } else {
                return e;
            }
        });
        consignmentsRef.current=updatedConsignments;
    }
    function deleteConsignment(consignmentIndex: number) {
        let updatedConsignments = consignmentsRef.current.filter((e, index) => index !== consignmentIndex);
        consignmentsRef.current = updatedConsignments;
        setConsignment(updatedConsignments);
    }
    
    function updateConsignmentPackageQuantity(consignmentIndex: number, quantity: number) {
        let updatedConsignments = consignmentsRef.current.map((e, index) => {
            if (index === consignmentIndex) {
                return { quantity: quantity, lines: e.lines };
            } else {
                return e;
            }
        });
        consignmentsRef.current = updatedConsignments;
    }
    const printConsignmentDocument = async () => {
        if (orderId && orderId !== "" && !isNaN(parseInt(orderId))) {
            const orderService = new OrderService();
            setLoader(true)
            const result = await orderService.postOrderConsignmentNoteCustomData(parseInt(orderId), consignmentsRef.current);
            setLoader(false);
            if (result.hasErrors) {
                toast("Klarer ikke å lage fraktbrev. Svar fra server: " + result.errors.join(", "));
            } else {
                window.open(URL.createObjectURL(result.value), "_blank");
            }
        }
    }

    return <Grid container component="main" direction="column" wrap="nowrap"
        sx={{
            width: "100%",
            height:"100%"
            //height: {
            //    //xs: "calc(100vh - " + appBarHeight + "px )",
            //    lg: "calc(100vh)"
            //},
        }}>
        <Container maxWidth="md" >
            <Paper sx={{ height: "100%", marginTop: theme.spacing(2) }}>        
                <List>
                    <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Grid container spacing={2}>
                            <Grid>
                                <Link to={"/Acinor/Orders"}>
                                    <Button variant="contained" color="info" endIcon={<ExitToApp />}>
                                        Avslutt
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid>
                                <Button variant="contained" color="secondary" endIcon={<RestartAlt />} onClick={() => setRevert(!revert) }>
                                    Tilbakestill
                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="contained" color="success" endIcon={<Print />} onClick={() => printConsignmentDocument()}>
                                    Print fraktbrev
                                </Button>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem sx={{ display:"flex", justifyContent:"center"}}>
                        <Typography variant="button" fontSize="1.2rem" fontWeight="bold">Rediger fraktbrev for</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container spacing={2}>
                                    <Grid container wrap="nowrap">
                                        <Grid><Typography fontWeight="bold">Ordre:</Typography></Grid>
                                        <Grid><Typography>{" " + orderId + " "}</Typography></Grid>
                                    </Grid>
                                    <Grid container >
                                        <Grid><Typography fontWeight="bold">Kunde:</Typography></Grid>
                                        <Grid><Typography>{consignmentNoteData?.customerName}</Typography></Grid>
                                    </Grid>
                                </Grid>
                            }
                            secondary={
                                <Grid container spacing={2}>
                                    {/*<Grid container wrap="nowrap">*/}
                                    {/*    <Grid><Typography fontWeight="bold">Ordredato:</Typography></Grid>*/}
                                    {/*    <Grid><Typography>{" " + orderDate + " "}</Typography></Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid container>
                                        <Grid><Typography fontWeight="bold">Leveringsadresse:</Typography></Grid>
                                        <Grid>
                                            <Typography>
                                                {
                                                    " " +
                                                    consignmentNoteData?.deliveryCity +
                                                    " " +
                                                    consignmentNoteData?.deliveryPostalCode +
                                                    ", " +
                                                    consignmentNoteData?.deliveryAddress
                                                }
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            } />
                    </ListItem>
                    <ListItem>
                        <Button variant="contained" fullWidth color="warning" onClick={addConsignment}>Legg til kolli</Button>
                    </ListItem>
                    {consignments.length > 0 ? consignments.map((c, index) => {
                        return <ListItem>
                            <ConsignmentTable
                                consignments={c}
                                consignmentIndex={index}
                                deleteConsignment={deleteConsignment}
                                updateConsignmentLine={updateConsignmentLine}
                                updateConsignmentPackageQuantity={updateConsignmentPackageQuantity} />
                        </ListItem>
                    }) : <ListItem sx={{ display:"flex", justifyContent:"center"}}>
                        <Typography variant="button" fontSize="1rem" fontWeight="bold">{"Ingen Kolli. " + consignmentNoteDataError}</Typography>
                    </ListItem>}
                </List>
            </Paper>
        </Container>
        <Backdrop sx={{ color: '#fff', }} open={loader}>
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress size="6rem" thickness={6} />
            </Box>
        </Backdrop>
    </Grid>
})

export default OrderConsignments;
