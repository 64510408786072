/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Toolbar, useTheme, Theme as MaterialTheme, Button, Box, Divider, List, SvgIconTypeMap, useMediaQuery, ListItem, ListItemButton, ListItemIcon, ListItemText, Drawer, SvgIcon, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, ListSubheader, Typography } from '@mui/material';
import { Abc, AccountCircle, AdminPanelSettings, Dashboard, Home, LegendToggle, Logout, Speed, Storage, Warehouse } from "@mui/icons-material";
import { ReactComponent as abcxyzIcon } from "../../common/icons/abcxyzIcon.svg"
import MainLogo from "../images/acinorlogo.jpg"
import SecondaryLogo from "../images/futurethenlogolight.png"
import { Link, useLocation, } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorMessage from './ErrorMessage';
import axios from 'axios';
import { removeTokenFromLocalStorage } from '../../App';

interface IProps {
    mobileOpen: boolean,
    drawerToggle: () => void,
    drawerWidth: string,
}

interface ILinkElement {
    elements: IElement,
}

interface IElement {
    id: string,
    name: string,
    link: string,
    iconFunction: JSX.Element,
    index: number,
    subLinks?: IElement[],
    open?: boolean,
    handleOpen?: (value: boolean) => void,
    subRoute?: number,
    handleSubRoute?: (numb: number) => void,
}
interface IMobileOrDesktopList {
    name: string,
    url: string,
    iconFunction: JSX.Element,
}


const rootDrawer = (theme: MaterialTheme) => css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
})
const logoMain = css({
    height: "2.5rem",
})
const logoSecondary = css({
    height: "3rem",
})

let listIndex = 0;

const Sidemenu = withErrorBoundary((props: IProps): JSX.Element => {

    const theme = useTheme();

    let location = useLocation()

    function isSelected(path: string) {
        if (path === location.pathname)
            return true;
        else
            return false;
    }

    function userLogout() {

        const logout = async () => {
            axios.defaults.headers.common['Authorization'] = "";
            removeTokenFromLocalStorage();
            window.location.replace("/");
        }
        logout();
    }

    const MobileOrDesktopList = (props: IMobileOrDesktopList): JSX.Element => {

        const { name, url, iconFunction } = props;

        listIndex = listIndex + 1;

        return <ListItem dense disableGutters sx={{
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
                bgcolor: theme.palette.secondary.main,
            },
            // hover states
            '& .MuiListItemButton-root:hover': {
                bgcolor: theme.palette.secondary.main,
            },
        }}>
            <ListItemButton component={Link} to={url} selected={isSelected(url)}>
                <ListItemIcon sx={{color:theme.palette.background.paper}}>
                    {iconFunction}
                </ListItemIcon>
                <ListItemText sx={{ color: theme.palette.background.paper }} primary={name}/>
            </ListItemButton>
        </ListItem>
    }

    const drawer = (

        <div css={rootDrawer(theme)}>

            <Box
                component={Button}
                sx={{
                    display: { xs: "none", xl: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    height: "4rem",
                    width: "100%",
                    bgcolor: theme.palette.background.paper,
                    marginTop: theme.spacing(4), marginBottom: theme.spacing(4),
                }}>
                <img src={MainLogo} css={logoMain} />
            </Box>
            <Toolbar sx={{ display: { xs: "block", xl: "none" } }} />
            <Divider sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), bgcolor: theme.palette.background.paper }} />
            <List>
                <ListItem
                    sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography color="white" variant="button">Ordrehåndtering</Typography>
                </ListItem>
            </List>
            <Divider sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), bgcolor: theme.palette.background.paper }} />
            <List>
                <MobileOrDesktopList name="Ordre" url="/Acinor/Orders/true" iconFunction={<Dashboard />} />
                <MobileOrDesktopList name="Alle Ordre" url="/Acinor/Orders/false" iconFunction={<Dashboard />} />
                <MobileOrDesktopList name="ADR Database" url="/Acinor/AdrDatabase" iconFunction={<Storage />} />
            </List>
            <Box flexGrow={1} />
            <Divider sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2), bgcolor: theme.palette.background.paper }} />
            <List>
                <MobileOrDesktopList name="Bruker Administrasjon" url="/Acinor/UserAdministration" iconFunction={<AdminPanelSettings />} />
                <ListItem dense disableGutters sx={{
                    // selected and (selected + hover) states
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: theme.palette.secondary.main,
                    },
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: theme.palette.secondary.main,
                    },
                }}>
                    <ListItemButton onClick={userLogout}>
                        <ListItemIcon sx={{ color: theme.palette.background.paper }}>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText sx={{ color: theme.palette.background.paper }} primary="Logg ut" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Box
                component={Button}
                sx={{
                    display: { xs: "none", xl: "flex" },
                    justifyContent: "center", alignItems: "center", height: "6rem", width: "100%",
                }}>
                <img src={SecondaryLogo} css={logoSecondary} />
            </Box>
        </div>

        );
    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <ErrorMessage message={message} />
    }

    return <>
        <Drawer
            variant="temporary"
            open={props.mobileOpen}
            onClose={props.drawerToggle} ModalProps={{ keepMounted: true, }}
            sx={{
                display: { xs: 'block', xl: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, },
            }}>
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', xl: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, },
            }}
            open
        >
            {drawer}
        </Drawer>
    </>

})

export default Sidemenu;