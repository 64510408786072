import { Typography } from "@mui/material";
interface IProps {
    message: string,
}



const ErrorMessage = (props: IProps): JSX.Element => {

    return <Typography border={1}>
        {props.message}
    </Typography>

}

export default ErrorMessage;