import { useTheme } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdrDatabase from "./areas/orderDocuments/routes/AdrDatabase";
import OrderLinesEdit from "./areas/orderDocuments/routes/OrderConsignments";
import Orders from "./areas/orderDocuments/routes/Orders";
import UserAdministration from "./areas/orderDocuments/routes/UserAdministration";
import AuthorizedLayout from "./common/layouts/AuthorizedLayout";
import GuestLayout from "./common/layouts/GuestLayout";
import LoginRoute from "./common/routes/LoginRoute";


const AppRoutes = (): JSX.Element => {

    const theme = useTheme();

    //Handle the login like you handle it in digiacc.

    return <BrowserRouter basename="/">
        <Routes>
            <Route path="/" element={<GuestLayout />}>
                <Route index element={<LoginRoute />} />
                <Route path="/:text" element={<LoginRoute />} />
                <Route path="*" element={
                    <main style={{ padding: theme.spacing(1) }}>
                        <p>Error 404: Page Not Found.</p>
                    </main>
                } />
            </Route>
            <Route path="/Acinor" element={<AuthorizedLayout />}>
                <Route path="/Acinor/Orders" element={<Orders />} />
                <Route path="/Acinor/Orders/:hideHiddenFromFrontPage" element={<Orders />} />
                <Route path="/Acinor/Order/:orderId" element={<OrderLinesEdit />} />
                <Route path="/Acinor/UserAdministration" element={<UserAdministration />} />
                <Route path="/Acinor/AdrDatabase" element={<AdrDatabase />} />
                <Route path="*" element={
                    <main style={{ padding: theme.spacing(1) }}>
                        <p>Error 404: Page Not Found.</p>
                    </main>
                } />
            </Route>
        </Routes>
    </BrowserRouter>
}

export default AppRoutes;
