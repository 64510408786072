/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { Theme as MaterialTheme, useTheme, Paper, Typography, Container, Box, Button, TextField, FormControlLabel, Checkbox, alpha } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useState, useEffect, Dispatch, SetStateAction, useContext, useLayoutEffect, } from "react";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import MainLogo from "../images/acinorlogo.jpg"
import Wallpaper from "../images/wallpapp.jpg";
import AuthenticationService from '../../services/AuthenticationService';
import { getTokenFromLocalStorage, saveTokenToLocalStorage } from '../../App';
//import AuthenticationService from '../../service/AuthenticationService';
//import { IAuthenticationModel } from '../../model/IAuthenticationModel';

const logoMain = css({
    height: "2.2rem",
})

const LoginRoute = (): JSX.Element => {

    const theme = useTheme();

    const { text } = useParams();

    const navigate = useNavigate();

    //const [loginInformation, setLoginInformation] = useState<IAuthenticationModel>();
    const [loginMessage, setLoginMessage] = useState<string>("");

    //##########################################################################################################

    const postLoginButton = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const handleLogin = async () => {
            const loginService = new AuthenticationService();
            const result = await loginService.postLogin(data.get("username") as string, data.get("password") as string);
            if (result.hasErrors) {
                toast("Feil med login. Svar fra server: " + result.errors.join(", "))
                setLoginMessage("Feil med login. Svar fra server: " + result.errors.join(", "));
            } else {
                axios.defaults.headers.common['Authorization'] = "Bearer " + result.value.token;
                saveTokenToLocalStorage("Bearer " + result.value.token);
                navigate("/Acinor/Orders");
            }
        };

        if (data.get("username") && data.get("password")) {
            handleLogin();
        } else {
            setLoginMessage("Login mangler epost eller passord");
            toast("Login mangler epost eller passord");
        }
        
    }

    useEffect(() => {

        const oldToken = getTokenFromLocalStorage();
        if (oldToken !== "") {
            axios.defaults.headers.common['Authorization'] = oldToken;
            navigate("/Acinor/Orders");
        }

    },[])

    useEffect(() => {
        if (text && text === "loggetut")
            setLoginMessage("Du ble logget ut på grunn av inaktivitet eller mangel på rettigheter.")
    },[text])

    return <main>
        <Grid container direction="column"
            sx={{
                width: "100%",
                minHeight: "30rem",
                height: "calc(100vh)",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: "url(" + Wallpaper + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                //"& .MuiDataGrid-columnHeaders": { backgroundColor: theme.palette.info.main, color: "white" },
                //"& .MuiDataGrid-footerContainer": { backgroundColor: theme.palette.info.main, color: "white" }
            }}>
            <Container sx={{
                bgcolor: alpha(theme.palette.background.paper, 0.9),
                minHeight: "20rem",
                padding: theme.spacing(4), borderRadius: theme.shape.borderRadius, border:1,
            }} maxWidth="xs">
                <Box sx={{
                    marginTop:"1rem",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Box
                        component={Button}
                        sx={{
                            display: "flex",
                            justifyContent: "center", alignItems: "center", width: "100%",
                        }}>
                        <img src={MainLogo} css={logoMain} />
                    </Box>
                    <Box component="form" onSubmit={postLoginButton} noValidate sx={{ marginTop: theme.spacing(1) }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Brukernavn"
                            name="username"
                            autoComplete="email"
                            autoFocus />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Passord"
                            type="password"
                            id="password"
                            autoComplete="current-password" />
                        <Typography color={theme.palette.error.main}>{loginMessage}</Typography>
                        <Button
                            type="submit" fullWidth variant="contained" color="primary" sx={{ mt: theme.spacing(3), mb: theme.spacing(2) }}>
                            Login
                        </Button>
                    </Box>
                </Box>
                <ToastContainer />
            </Container>
        </Grid>
        <ToastContainer />
    </main>

}
export default LoginRoute;