/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, useTheme } from '@mui/material';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Appbar from '../components/Appbar';
import Sidemenu from '../components/Sidemenu';

const root = css({
    display: "flex",
    flexDirection:"column"
})

const drawerWidth = "12rem";

const AuthorizedLayout = (): JSX.Element => {
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return <div css={root}>
        <Box sx={{ display: { xs: "block", xl: "none" } }}>
            <Appbar drawerToggle={handleDrawerToggle} />
        </Box>
        <Box display="flex">
            <Box component="nav" sx={{ width: { xl: drawerWidth }, flexShrink: { sm: 0 } }}>
                <Sidemenu
                    mobileOpen={mobileOpen}
                    drawerToggle={handleDrawerToggle}
                    drawerWidth={drawerWidth} />
            </Box>
            <Box component="main" sx={{ width: { xs: "100%", xl: `calc(100% - ${drawerWidth})` } }}>
                <Outlet />
            </Box>
        </Box>
    </div>

}

export default AuthorizedLayout;