/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Menu } from '@mui/icons-material';
import { Toolbar, useTheme, Theme as MaterialTheme, useMediaQuery, Box, AppBar, IconButton, Button, } from '@mui/material';
import MainLogo from "../images/acinorlogo.jpg";
import SecondaryLogo from "../images/futurethenlogolight.png";


interface IProps {
    drawerToggle: () => void,
}

const logoMain = css({
    height: "2rem",
    backgroundColor: "white",
    padding: "0.3rem"
})
const logoSecondary = css({
    height: "2rem",
})



const Appbar = (props: IProps): JSX.Element => {

    const theme = useTheme();

    return <>
        <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.main }}>
            <Toolbar sx={{ display: "flex", justifyContent:"space-between" }}>
                <Box
                    display="flex" alignItems="center">
                    <IconButton onClick={props.drawerToggle}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <Menu />
                    </IconButton>
                    <img src={MainLogo} css={logoMain} />
                </Box>             
                <Box
                    component={Button}
                    display="flex" justifyContent="center" alignItems="center">
                    <img src={SecondaryLogo} css={logoSecondary} />
                </Box>
            </Toolbar>
        </AppBar>
        <Toolbar />
    </>

}

export default Appbar;