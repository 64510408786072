import { IConsignmentNoteModel, IConsignmentsModel } from '../models/IConsignmentNoteModel';
import { IOrderModel } from '../models/IOrderModel';
import { DefaultResult, Result } from '../rest_api_connection/Result';
import { ServiceBase } from '../rest_api_connection/ServiceBase';


export default class OrderService extends ServiceBase {

    public async getOrderList(hiddenFromFrontPage?:boolean): Promise<Result<IOrderModel[]>> {

        let url = "/Orders";

        if (hiddenFromFrontPage) {
            url = url + "?hiddenFromFrontPage=true";
        }

        var result = await this.requestJson<IOrderModel[]>({
            url: url,
            method: "GET",
        });

        return result;
    }

    public async getOrderFile(orderId: number): Promise<Result<Blob>> {

        let url = "/Orders/" + orderId + "/consignmentNote";

        var result = await this.requestFile<Blob>({
            url: url,
            method: "GET",
        });

        return result;
    }

    public async postOrderImage(orderId: number, image: File): Promise<DefaultResult> {

        let url = "/Orders/" + orderId + "/consignmentNote";

        const form = new FormData();
        form.append("image", image);

        var result = await this.sendFormData({
            url: url,
            method: "POST",
            data: form,
        });
        return result;
    }

    public async getOrderConsignmentNoteData(orderId: number): Promise<Result<IConsignmentNoteModel>> {

        let url = "/Orders/" + orderId + "/consignmentNote/data";

        var result = await this.requestJson<IConsignmentNoteModel>({
            url: url,
            method: "GET",
        });

        return result;
    }
    public async postOrderConsignmentNoteCustomData(orderId: number, consignments: IConsignmentsModel[]): Promise<Result<Blob>> {

        let url = "/Orders/" + orderId + "/consignmentNote/custom";

        let data = {
            consignments: consignments.map(e => {
                return {
                    quantity: e.quantity,
                    lines: e.lines.map(l => {
                        return {
                            quantity: 0,
                            description: l.description,
                            weight: l.weight ? l.weight : null,
                            adr: l.adrCode ? l.adrCode : null
                        }
                    })
                }
            })
        } 

        var result = await this.requestFile<Blob>({
            url: url,
            method: "POST",
            data: data,
        });

        return result;
    }

    public async postOrderHiddenFromFrontPage(orderId: number, state: boolean): Promise<DefaultResult>{

        let url = "/Orders/" + orderId + "/properties/user";

        let data = { orderId: orderId, hiddenFromFrontPage: state }

        var result = await this.requestJsonDefault({
            url: url,
            method: "POST",
            data: data,
        });
        return result;
    }
}