import { IAuthenticationModel } from '../models/IAuthenticationModel';
import { Result } from '../rest_api_connection/Result';
import { ServiceBase } from '../rest_api_connection/ServiceBase';

export default class AuthenticationService extends ServiceBase {

    public async postLogin(email: string, password: string): Promise<Result<IAuthenticationModel>> {

        let url = "/login";

        var result = await this.requestJson<IAuthenticationModel>({
            url: url,
            method: "POST",
            data: { email: email, password: password }
        });
        return result;
    }
}
