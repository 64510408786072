import { Result } from "./Result";

export default class PageResult<T> extends Result<T> {
    //public value: T;
    //public errors: string[];
    //public get hasErrors(): boolean {
    //    return this.errors != null && Array.isArray(this.errors) && this.errors.length > 0;
    //}

    public currentPage: number;
    public totalPages: number;
    public totalItems: number;


    constructor(code: number | null, value: T, currentPage: number, totalPages: number, totalItems: number, ...errors: string[]) {
        super(code, value, ...errors);
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.totalItems = totalItems;
    }
}