/** @jsxImportSource @emotion/react */
import { css, Theme as EmotionTheme } from '@emotion/react';
import { alpha, tableCellClasses, Theme, useTheme } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';

const OPACITY = 0.6;
export function stripedDateGrid(theme: Theme, backColor: string) {

    return css({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: backColor,
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.secondary.main, OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.secondary.main,
                        OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.secondary.main,
                            OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
        [`& .${gridClasses.row}.odd`]: {
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(theme.palette.secondary.main, OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover, &.Mui-hovered': {
                    backgroundColor: alpha(
                        theme.palette.secondary.main,
                        OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.secondary.main,
                            OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
        border: 0,
    }); 
};

export const textBoxInputStyle = (theme: Theme) => css({
    backgroundColor: theme.palette.background.paper,
    fontSize: "0.7rem",
    fontWeight: "bold",
})

export const StylesTableCell = (theme: Theme, boxColor?: string, textColor?: string) => css({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: boxColor ? boxColor : theme.palette.grey[600],
        color: textColor ? textColor : theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
})
export const StylesTableRow = (theme: Theme) => css({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    }
})