/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';

import { withErrorBoundary } from 'react-use-error-boundary';
import { ChangeEvent, useEffect, useState } from 'react';
import { Delete,} from '@mui/icons-material';
import { IConsignmentLinesModel, IConsignmentsModel } from '../../../models/IConsignmentNoteModel';
import { StylesTableCell, textBoxInputStyle } from '../../../common/CustumEmotionStyles';

interface IConsignmentTableModel {
    consignments: IConsignmentsModel,
    consignmentIndex: number,
    deleteConsignment: (consignmentIndex: number) => void,
    updateConsignmentLine: (consignmentIndex: number, newlines: IConsignmentLinesModel[]) => void,
    updateConsignmentPackageQuantity: (consignmentIndex: number, quantity: number) => void,
}

const ConsignmentTable = withErrorBoundary((props: IConsignmentTableModel): JSX.Element => {

    const theme = useTheme();

    const [packageQuantity, setPackageQuantity] = useState<number | null>(props.consignments.quantity);
    const [consignmentLines, setConsignmentLines] = useState(props.consignments.lines);

    useEffect(() => {

        setPackageQuantity(props.consignments.quantity);
        setConsignmentLines(props.consignments.lines);

    }, [props.consignments]);
    function handlePackageQuantityUpdate(quantity: string) {
        let newQuantity = 0;
        if (quantity !== "" && quantity !== " " && !isNaN(parseInt(quantity))) {
            newQuantity = parseInt(quantity);
        }
        setPackageQuantity(newQuantity);
        props.updateConsignmentPackageQuantity(props.consignmentIndex, newQuantity);
    }
    
    function handleAddConsignmentLine() {
        let newConsignmentLine: IConsignmentLinesModel = { markings: "", description: "", weight: null, adrCode: null };
        let newLines = [...consignmentLines];
        newLines.push(newConsignmentLine)
        setConsignmentLines(newLines);
        props.updateConsignmentLine(props.consignmentIndex, newLines);
    }
    function handleDeleteConsignment() {
        props.deleteConsignment(props.consignmentIndex);
    }
    function handleDeleteConsignmentLine(lineIndex: number) {
        let updatedConsignmentLines = consignmentLines.filter((e, index) => index !== lineIndex);
        setConsignmentLines(updatedConsignmentLines);
        props.updateConsignmentLine(props.consignmentIndex, updatedConsignmentLines)
    }
    function handleUpdateConsignmentLine(lineIndex: number, type: "description" | "weight" | "adr", value: string) {
        let updatedLine: IConsignmentLinesModel;
        if (type === "weight" || type === "adr") {
            if (value !== "" && value !== " " && !isNaN(parseInt(value))) {
                let newValue = parseInt(value);
                updatedLine = {
                    markings: consignmentLines[lineIndex].markings,
                    description: consignmentLines[lineIndex].description,
                    weight: type === "weight" ? newValue : consignmentLines[lineIndex].weight,
                    adrCode: type === "adr" ? newValue : consignmentLines[lineIndex].adrCode,
                }
            } else {
                updatedLine = {
                    markings: consignmentLines[lineIndex].markings,
                    description: consignmentLines[lineIndex].description,
                    weight: type === "weight" ? null : consignmentLines[lineIndex].weight,
                    adrCode: type === "adr" ? null : consignmentLines[lineIndex].adrCode,
                }
            }
        } else {
            updatedLine = {
                markings: consignmentLines[lineIndex].markings,
                description: value,
                weight: consignmentLines[lineIndex].weight,
                adrCode: consignmentLines[lineIndex].adrCode
            }
        }
        let updatedConsignmentLines = consignmentLines.map((e, index) => {
            if (index === lineIndex) {
                return updatedLine;
            } else {
                return e;
            }
        });
        setConsignmentLines(updatedConsignmentLines);
        props.updateConsignmentLine(props.consignmentIndex, updatedConsignmentLines)
    }

    return <TableContainer component={Paper} sx={{ bgcolor: theme.palette.primary.light }}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell css={StylesTableCell(theme, theme.palette.grey[800])}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <Typography variant="body1" fontWeight="bold" color={theme.palette.common.white}>{props.consignmentIndex + ". Antall kolli:"}</Typography>
                            <TextField
                                sx={{
                                    bgcolor: theme.palette.common.white,
                                    borderRadius: theme.spacing(1), width: "6rem", minWidth: "4rem"
                                }}
                                size="small"
                                variant="outlined"
                                color="warning"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                type="number"
                                value={packageQuantity}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handlePackageQuantityUpdate(e.target.value)}/>
                        </Stack>
                    </TableCell>
                    <TableCell css={StylesTableCell(theme, theme.palette.grey[800])} colSpan={2}>
                        <Button fullWidth variant="contained" color="secondary" onClick={handleAddConsignmentLine}>Legg til ny ordrelinje</Button>
                    </TableCell>
                    <TableCell css={StylesTableCell(theme, theme.palette.grey[800])}>
                        <Button fullWidth variant="contained" color="error" endIcon={<Delete />} onClick={handleDeleteConsignment}>Slett kolli</Button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell css={StylesTableCell(theme)} style={{ minWidth: "16rem" }}><Typography variant="button" color={theme.palette.common.white}>Type pakning - godssalg</Typography></TableCell>
                    <TableCell css={StylesTableCell(theme)} style={{ minWidth: "9rem", width: "9rem" }}><Typography variant="button" color={theme.palette.common.white}>Bruttovekt:</Typography></TableCell>
                    <TableCell css={StylesTableCell(theme)} style={{ minWidth: "9rem", width: "9rem" }}><Typography variant="button" color={theme.palette.common.white}>ADR Kode:</Typography></TableCell>
                    <TableCell css={StylesTableCell(theme)}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {consignmentLines.map((e, index) => {
                    return <TableRow>
                        <TableCell css={StylesTableCell(theme)}>
                            <OutlinedInput
                                value={e.description}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateConsignmentLine(index, "description", e.target.value)}
                                fullWidth
                                size="small"
                                color="warning"
                                css={textBoxInputStyle(theme)}
                                style={{ padding: 0 }}
                                id="articleName" />
                        </TableCell>
                        <TableCell css={StylesTableCell(theme)}>
                            <OutlinedInput
                                value={e.weight}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateConsignmentLine(index, "weight", e.target.value)}
                                fullWidth
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                type="number"
                                size="small"
                                color="warning"
                                css={textBoxInputStyle(theme)}
                                id="quantity" />
                        </TableCell>
                        <TableCell css={StylesTableCell(theme)}>
                            <OutlinedInput
                                value={e.adrCode}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateConsignmentLine(index, "adr", e.target.value)}
                                fullWidth
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                type="number"
                                size="small"
                                color="warning"
                                css={textBoxInputStyle(theme)}
                                id="quantity" />
                        </TableCell>
                        <TableCell css={StylesTableCell(theme)} align="center">
                            <Button fullWidth variant="contained" endIcon={<Delete />} onClick={() => handleDeleteConsignmentLine(index)}>Slett rad</Button>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
})

export default ConsignmentTable;
