import { IUserModel } from '../models/IUserModel';
import { DefaultResult, Result } from '../rest_api_connection/Result';
import { ServiceBase } from '../rest_api_connection/ServiceBase';


export default class UserService extends ServiceBase {

    public async getUserList(
    ): Promise<Result<IUserModel[]>> {

        let url = "/User";

        var result = await this.requestJson<IUserModel[]>({
            url: url,
            method: "GET",
        });

        return result;
    }

    public async postUser(email: string, fullName: string, password: string, isAdmin?: boolean): Promise<DefaultResult> {

        let url = "/User";
        let newIsAdmin = false;
        if (isAdmin)
            newIsAdmin = true;

        var result = await this.requestJsonDefault({
            url: url,
            method: "POST",
            data:
            {
                email: email,
                fullName: fullName,
                isAdmin: newIsAdmin,
                password: password,
            }
        });
        return result;
    }

    public async deleteUser(email: string, token?: string): Promise<DefaultResult> {

        let url = "/User/" + email;

        var result = await this.requestJsonDefault({
            url: url,
            method: "DELETE",
        });
        return result;
    }
}