/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Collapse, Container, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material';

//import { ReactComponent as ProductIcon } from "../../../common/icons/productIcon.svg"

import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import ErrorMessage from '../../../common/components/ErrorMessage';
import { add, format } from 'date-fns';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CheckCircle, Delete, Edit } from '@mui/icons-material';
import { IUserModel } from '../../../models/IUserModel';
import UserService from '../../../services/UserService';
import { toast } from 'react-toastify';

interface IOrders {
    orderNr: number,
    customerNa: string,
    orderDate: Date,
    deliveryDate: Date,
}

const UserAdministration = withErrorBoundary((): JSX.Element => {

    const theme = useTheme();

    //###############################################################################

    const [newUser, setNewUser] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [isAdmin, setIsAdmin] = useState(false);
    const handleIsAdminCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setIsAdmin(event.target.checked);
    }

    const [loader, setLoader] = useState(false);

    const [users, setUsers] = useState<IUserModel[]>([])
    const [usersToggle, setUsersToggle] = useState(false);

    function cancelAddUser() {
        setNewUser(false);
        setEmail("");
        setFullName("");
        setPassword("");
        setIsAdmin(false);
    }
    function addUser() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email && re.test(email)) {
            if (password && password.length > 7) {
                if (fullName && fullName !== "") {
                    servicePostUser(email, fullName, password, isAdmin);
                } else {
                    toast("Mangler fullt navn. Kan ikke legge til bruker.")
                }
            } else {
                toast("Ingen eller for dårlig passord brukt. Bruk minst 8 tegn.")
            }
        } else {
            toast("Ugyldig epost brukt. Kan ikke legge til bruker.")
        }

    }

    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    const serviceGetUsers = async (isMounted: boolean) => {
        setLoader(true);
        const userService = new UserService();
        const result = await userService.getUserList();
        setLoader(false);
        if (result.hasErrors) {
            if (isMounted) setUsers([]);
            if (isMounted) toast("Kan ikke hente brukerliste. Svar fra server: " + result.errors.join(", "));
        } else {
            if (isMounted) setUsers(result.value);
        }
    }
    const serviceDeleteUser = async (email: string) => {
        if (users.length !== 1) {
            setLoader(true);
            const userService = new UserService();
            const result = await userService.deleteUser(email);
            setLoader(false);
            if (result.hasErrors) {
                toast("Kan ikke slette bruker. Svar fra server: " + result.errors.join(", "));
            } else {
                toast("Bruker slettet!");
                setUsersToggle(!usersToggle);
            }
        } else {
            toast("Kan ikke slette siste brukeren!");
        }
        
    }
    const servicePostUser = async (email: string, fullName: string, password: string, isAdmin: boolean) => {
        const userService = new UserService();
        const result = await userService.postUser(email, fullName, password, isAdmin);
        if (result.hasErrors) {
            toast("Kan ikke å legge til ny bruker. Svar fra server: " + result.errors.join(", "));
        } else {
            toast("Ny bruker lagt til!")
        }
        setEmail("");
        setFullName("");
        setPassword("");
        setIsAdmin(false);
        setUsersToggle(!usersToggle);
    }

    useEffect(() => {
        let isMounted = true; //Avoid memory leaks. Async function running after dismount.

        serviceGetUsers(isMounted);

        return () => { isMounted = false };

    }, [usersToggle]);


    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <ErrorMessage message={message} />
    }
    return <Grid container component="main" direction="column" wrap="nowrap" spacing={2}
        sx={{
            padding: theme.spacing(4),
            width: "100%",
            height:"100%"
            //height: {
            //    //xs: "calc(100vh - " + appBarHeight + "px )",
            //    lg: "calc(100vh)"
            //},
        }}>
        <Container maxWidth="md" >
            <Paper sx={{ height: "100%", padding: theme.spacing(4) }}>
                <Button
                    onClick={() => setNewUser(true) }
                    sx={{ display: newUser ? "none" : "block" }}
                    fullWidth
                    variant="contained">
                    Legg til ny bruker
                </Button>
                <Collapse in={newUser} timeout="auto" unmountOnExit
                    sx={{
                        border: 1,
                        borderRadius: theme.shape.borderRadius,
                        borderColor: theme.palette.grey[500],
                        padding: "1rem"
                    }}>
                    <Grid container spacing={2}>
                        <Grid xs={6} md={5} minWidth="8rem">
                            <TextField fullWidth size="small" variant="outlined" color="warning" value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                id="fullNameId" label="Fullt navn" />
                        </Grid>
                        <Grid xs={6} md={4} minWidth="8rem">
                            <TextField fullWidth size="small" variant="outlined" color="warning" value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="emailId" label="Epost" />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField fullWidth size="small" variant="outlined" color="warning" value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                id="passwordId" label="Passord" />
                        </Grid>
                        <Grid xs={6}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={<Checkbox checked={isAdmin} onChange={handleIsAdminCheck} />}
                                    label="Admin?"
                                    labelPlacement="start" />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <Button onClick={() => addUser()} fullWidth variant="contained" color="secondary">Legg til</Button>
                        </Grid>
                        <Grid xs={12}>
                            <Button onClick={() => cancelAddUser()} fullWidth variant="contained" color="error">Avbryt</Button>
                        </Grid>
                    </Grid>
                </Collapse>
                <List sx={{marginTop:"1rem"}} subheader={
                    <ListSubheader>
                        <Typography variant="h6">Se/Slett eksiterende brukere:</Typography>
                    </ListSubheader>
                }>
                    {users.map((e, index) => {

                        return <Box sx=
                            {{
                                marginLeft: theme.spacing(1), marginRight: theme.spacing(1), marginTop: theme.spacing(1),

                                bgcolor: index % 2 === 0 ? theme.palette.secondary.dark : theme.palette.secondary.main,
                                borderColor: theme.palette.secondary.main,
                            }}>
                            <ListItem sx={{
                                marginTop: theme.spacing(2),
                                border: 1,
                                borderRadius: theme.shape.borderRadius,
                            }}>
                                <ListItemText>{e.fullName + " - " + e.email + " - " + ( e.isAdmin ? "Admin" : "Bruker" )}</ListItemText>
                                <ListItemIcon>
                                    <IconButton onClick={() => serviceDeleteUser(e.email)}>
                                        <Delete color="error" />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                        </Box>
                    })}
                </List>
            </Paper>
        </Container>
        <Backdrop sx={{ color: '#fff', }} open={loader}>
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                <CircularProgress size="6rem" thickness={6} />
            </Box>
        </Backdrop>
    </Grid>
})

export default UserAdministration;
