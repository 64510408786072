/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useTheme, Box} from '@mui/material';
import { Outlet } from 'react-router-dom';

const root = css({
    display: "flex",
    flexDirection:"column"
})

const GuestLayout = (): JSX.Element => {

    return <div css={root}>
        <Outlet />
    </div>

}

export default GuestLayout;