import { useState } from 'react';
import Axios from "axios";
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import nbLocale from 'date-fns/locale/nb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { nbNO } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AppRoutes from './AppRoutes';
import { useErrorBoundary, withErrorBoundary } from 'react-use-error-boundary';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwtDecode from 'jwt-decode';
import { RedirectToLogin } from './utils';


export function saveTokenToLocalStorage(token: string) {
    localStorage.setItem("userToken", token);
}
export function getTokenFromLocalStorage() {
    //Also checks for expiration
    const token = localStorage.getItem("userToken");
    if (token) {
        const decoded: any = jwtDecode(token);
        if (decoded && decoded.exp) {
            const tokenTime = parseInt(decoded.exp)*1000;
            const todayTime = new Date().getTime();
            if (todayTime < tokenTime) {
                return token
            } else {
                removeTokenFromLocalStorage();
                RedirectToLogin();
                return ""
            }
        } else {
            return ""
        }
    }
    else {
        return "";
    }
        
}
export function removeTokenFromLocalStorage() {
    localStorage.removeItem("userToken");
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#3b3355',
        },
        secondary: {
            main: '#c5c0d8',
        },
        error: {
            main: '#d0253e',
        },
        info: {
            main: '#079cc5',
        },
        success: {
            main: '#20bf55',
        },
        background: {
            default: '#f5f5f5',
        },
    },
    spacing: (factor: number) => `${0.25 * factor}rem`,
    shape: {
        borderRadius: 2,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {

            }
        }
    },
}, nbNO);


//######Edit API connection######
if (process.env!.NODE_ENV === "production") {
    Axios.defaults.baseURL = "/api";
} else if (process.env!.NODE_ENV === "development") {
    Axios.defaults.baseURL = "https://localhost:7179/";
} else {
    // set defaults
}

const App = withErrorBoundary((): JSX.Element => {

    const [error, resetError] = useErrorBoundary((error, errorInfo) => {
        console.info("componentDidCatch handler called");
        console.error(error);
        console.error(errorInfo);
    });

    if (error) {
        const message = error instanceof Error ? error.message : (error as string);
        return <>
            <div>Error: {message}</div>
            <button
                onClick={() => {
                    resetError();
                }}
            >
                Reset Error
            </button>
        </>
    }

    return <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nbLocale} >
            <AppRoutes />
        </LocalizationProvider>
        <ToastContainer />
    </ThemeProvider>
})

export default App;
